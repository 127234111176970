<template>
    <div class="dataListPage">
        <!-- 头部大图 -->
        <div class="dlBigImg"><img src="http://iv.vu818.com/img/wzyt.jpg" ref="img" /></div>
        <!-- 文章头部 -->
        <div class="dlpBox" ref="box">
            <div class="dlpTitle" :style="{ 'margin-top': marginTop }">
                <!-- 上一篇下一篇 -->
                <div class="dlpBackNext">
                    <div class="dlpBack" :class="pageObj.beforeafter.before_id ? '' : 'default'" @click="prev">
                        <div><img src="https://iv.vu818.com/img/vu818.png" alt="" /></div>
                        <span>上一篇：{{ pageObj.beforeafter.before_title }}</span>
                    </div>
                    <div class="dlpNext" :class="pageObj.beforeafter.after_id ? '' : 'default'" @click="next">
                        <span>下一篇：{{ pageObj.beforeafter.after_title }}</span>
                        <div><img src="https://iv.vu818.com/img/vu818.png" alt="" /></div>
                    </div>
                </div>
                <div class="dlpBig">{{ pageObj.title }}</div>
                <div class="dlpSmall">
                    <p>{{ pageObj.create_time }}</p>
                    <!-- <div class="crLook">
                        <div class="clImg">
                            <img src="http://iv.vu818.com/img/vu818.png" />
                        </div>
                        <p>{{ pageObj.look_nums }}</p>
                    </div> -->
                </div>
                <!-- 简介 -->
                <p>
                    <img src="https://iv.vu818.com/img/vu818dj2.png" alt="" />
                    {{ pageObj.intro }}
                </p>
                <img src="https://iv.vu818.com/img/vu818dj1.png" />
            </div>
        </div>
        <!-- 内容 -->
        <div class="dlpContent">
            <div class="editor-p" v-html="pageObj.details"></div>
        </div>
        <!-- 底部推荐的相关解决方案 -->
        <div v-if="solutions.id">
            <h1 style="text-align: center; margin: 40px 0 50px">解决方案</h1>
            <div class="dlpFooter">
                <div class="top">
                    <h3>{{ solutions.title }}</h3>
                    <p>
                        {{ solutions.intro }}
                    </p>
                    <img :src="solutions.list_image" />
                </div>
                <ul>
                    <li v-for="item in solutions.children" :key="item.id" @click="$router.push(`/SolutionDetails?id=${item.id}`)">
                        <div>
                            {{ item.title }}
                            <div><img src="https://iv.vu818.com/img/vu818.png" /></div>
                        </div>
                        <p>{{ item.intro }}</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            pageObj: {
                beforeafter: {
                    after_id: undefined,
                    after_title: undefined,
                    before_id: undefined,
                    before_title: undefined,
                },
            },
            marginTop: "",
        };
    },
    created() {
        this.getData();
    },
    methods: {
        // 上一个
        prev() {
            let id = this.pageObj.beforeafter.before_id;
            if (!id) return;
            this.$bus.$emit("openLoading");
            this.$router.push("/movementDetail/" + id);
            this.getData();
        },
        // 下一个
        next() {
            let id = this.pageObj.beforeafter.after_id;
            if (!id) return;
            this.$bus.$emit("openLoading");
            this.$router.push("/movementDetail/" + id);
            this.getData();
        },
        async getData() {
            let { data } = await axios.get("/api/article/beforeafter", { params: { id: this.$route.params.id } });
            this.pageObj = data;
            if (!data.status) {
                this.$router.replace("/movement");
                return;
            }
            this.$nextTick(() => {
                setTimeout(() => {
                    // 遮住大图三分之一
                    // this.marginTop = `-${this.$refs.img.offsetHeight / 3}px`;
                    this.marginTop = `-${this.$refs.box.offsetHeight / 1.5}px`;
                    // console.log(this.$refs.img.offsetHeight);
                }, 100);
            });
        },
    },
    computed: {
        solutions() {
            return this.pageObj.solutions ? this.pageObj.solutions : { children: [] };
        },
    },
};
</script>

<style lang="less" scoped>
.dataListPage {
    // 头部大图
    .dlBigImg {
        width: 100%;
        height: 65vh;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    // 文章内容
    .dlpBox {
        width: 80%;
        margin: 0 auto;
        // 标题
        .dlpTitle {
            width: 100%;
            max-width: 1200px;
            margin: 0 auto;
            border-radius: 8px;
            box-shadow: 0px 9px 14px rgba(245, 245, 247, 0.8);
            // margin-top: -100px;
            position: relative;
            z-index: 99;
            background-color: white;
            padding: 40px;
            .dlpBackNext {
                width: 100%;
                height: 50px;
                line-height: 50px;
                text-align: center;
                position: absolute;
                left: 0px;
                top: -50px;
                padding: 0 20px;
                display: flex;
                justify-content: space-between;
                .default {
                    cursor: default !important;
                    &:hover {
                        color: black !important;
                        text-decoration: none !important;
                    }
                }
                .dlpBack,
                .dlpNext {
                    cursor: pointer;
                    padding: 0 25px;
                    position: relative;
                    user-select: none;
                    &:hover {
                        color: #7781f1;
                        text-decoration: underline;
                    }
                    & > div {
                        position: absolute;
                        width: 30px;
                        height: 50px;
                        overflow: hidden;
                        transform: scale(0.3);
                        & > img {
                            position: absolute;
                            width: 1200px;
                        }
                    }
                }
                .dlpBack {
                    & > div {
                        left: 0px;
                        top: 0px;
                        & > img {
                            top: -643px;
                            left: -176px;
                        }
                    }
                }
                .dlpNext {
                    & > div {
                        right: 0px;
                        top: 0px;
                        & > img {
                            top: -643px;
                            left: -208px;
                        }
                    }
                }
            }
            & > img {
                position: absolute;
                right: -40px;
                bottom: -40px;
            }
            & > p {
                text-align: left;
                text-indent: 36px;
                font-size: 18px;
                line-height: 34px;
                position: relative;
                & > img {
                    position: absolute;
                    width: 60px;
                    top: -22px;
                    left: 0px;
                }
            }
            .dlpBig {
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-size: 30px;
                font-weight: 900;
                text-align: center;
            }
            .dlpSmall {
                width: 50%;
                height: 50px;
                line-height: 50px;
                margin: 0 auto;
                text-align: center;
                font-size: 20px;
                color: gray;
                display: flex;
                justify-content: space-evenly;
                .crLook {
                    .clImg {
                        width: 30px;
                        height: 85%;
                        float: left;
                        position: relative;
                        overflow: hidden;
                        img {
                            width: 1000px;
                            height: 1500px;
                            position: absolute;
                            top: -41px;
                            right: -309px;
                        }
                    }
                    p {
                        float: left;
                    }
                }
                .iconfont {
                    width: 40%;
                    height: 50px;
                    float: left;
                    font-size: 20px;
                }
            }
        }
        // 内容
        .dlpContent {
            width: 100%;
            // min-height: 800px;
            margin-top: 50px;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-evenly;
                flex-direction: column;
                .dlpLi {
                    width: 100%;
                    min-height: 25px;
                    line-height: 25px;
                    margin-top: 25px;
                }
                .dlpLiB {
                    width: 100%;
                    height: 100%;
                    margin-top: 80px;
                    border-bottom: 1px solid gray;
                }
            }
        }
        // 上一篇下一篇
    }
}
.dlpFooter {
    text-align: left;
    font-size: 14px;
    padding-bottom: 10px;
    max-width: 1200px;
    margin: 0 auto;
    .top {
        background-color: #f2f5f7;
        padding: 28px;
        overflow: hidden;
        position: relative;
        width: 100%;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        p {
            padding: 28px 0;
            width: 70%;
            position: relative;
            z-index: 1;
        }
        h3 {
            font-size: 24px;
        }
        ul {
            display: flex;
            align-content: center;

            li {
                margin-right: 20px;
                img {
                    width: 20px;
                    vertical-align: middle;
                }
            }
        }
        & > img {
            position: absolute;
            bottom: 0;
            right: -60px;
            width: 714px;
            height: auto;
        }
    }
    & > ul {
        display: flex;
        flex-wrap: wrap;
        border: 1px solid #f2f5f7;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        overflow: hidden;
        & > li {
            width: 33.3333%;
            padding: 28px;
            border-right: 1px solid #f2f5f7;
            border-bottom: 1px solid #f2f5f7;

            cursor: pointer;
            &:hover {
                & > div {
                    color: #7781f1;
                    div {
                        animation: an 1s infinite;
                        @keyframes an {
                            0% {
                                transform: translateX(0px);
                            }
                            50% {
                                transform: translateX(5px);
                            }
                            100% {
                                transform: translateX(0px);
                            }
                        }
                    }
                }
            }
            & > div {
                position: relative;
                margin-bottom: 16px;

                div {
                    position: absolute;
                    width: 26px;
                    height: 20px;
                    overflow: hidden;
                    top: 0px;
                    right: 0px;
                    & > img {
                        position: absolute;
                        top: -105px;
                        left: -1340px;
                    }
                }
            }
        }
    }
}
</style>
